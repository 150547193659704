import {HasUUID} from '../has-uuid';
import {Uuid} from '../uuid';
import {RikData} from '../rik-data';
import {Location} from '../location/location';

export class MyWorkshop implements HasUUID {
  uuid: Uuid;
  name: string;
  description: string;
  phone: string;
  email: string;
  opens_at: string;
  arrive_before: string;
  closes_at: string;
  location: Location;

  constructor(data: any) {
    const rikData = new RikData(data);
    this.uuid = rikData.requireExists('uuid');
    this.name = rikData.requireExists('name');
    this.description = rikData.requireExists('description');
    this.phone = rikData.requireExists('phone');
    this.email = rikData.requireExists('email');
    this.opens_at = rikData.requireExists('opens_at');
    this.arrive_before = rikData.requireExists('arrive_before');
    this.closes_at = rikData.requireExists('closes_at');
    this.location = rikData.requireObject('location', Location);
  }
}
