import {GlobalAppConfig} from '../../configuration_old/global-app-config';

export class EnvironmentService {
  languageCodeSweden = 'sv';
  languageCodeNorway = 'nb';

  constructor(private globalAppConfig: GlobalAppConfig) {
  }

  public get isLocal(): boolean {
    return this.globalAppConfig.envLabel === 'LOCAL';
  }

  public get label(): string {
    return this.globalAppConfig.envLabel || '';
  }

  public get homepageUrl(): string {
    return this.globalAppConfig.homepageUrl || '';
  }

  public get mapsUrl(): string {
    return this.globalAppConfig.googleMapsUrl || '';
  }

  public get themeCss(): string {
    return this.globalAppConfig.themeCss || '';
  }

  public get signatureOrgno(): string {
    return this.globalAppConfig.signatureOrgno || '';
  }

  public get languageCode(): string {
    if (this.isNorway) {
      return this.languageCodeNorway;
    }
    return this.languageCodeSweden;
  }

  public get countryCode(): string {
    if (!this.globalAppConfig.countryCode) {
      return 'se';
    }
    return this.globalAppConfig.countryCode;
  }

  public get isNorway(): boolean {
    return this.countryCode === 'no';
  }

  setHtmlLang(lang: string): void {
    document.documentElement.lang = lang;
  }

  public get apiEndpoint(): string {
    return this.globalAppConfig.apiEndpoint || '';
  }

  public get jwtToken(): string {
    return this.globalAppConfig.apiToken || '';
  }

  public get assetsUrl(): string {
    return this.globalAppConfig.assetsUrl || './assets';
  }

  public get hasPlayground(): boolean {
    return this.isDebug;
  }

  public get isDebug(): boolean {
    return this.globalAppConfig.debug || false;
  }

  public get hasFeatureExtraGlassTreatment(): boolean {
    return this.globalAppConfig.featureExtraGlassTreatment || false;
  }

  public hasFeature(name: string): boolean {
    const property = this.globalAppConfig[`feature${name}`];
    return property === true;
  }

  public url(name: string): boolean {
    return this.globalAppConfig[`${name}Url`];
  }
}
