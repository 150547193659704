import {GlobalAppConfig} from '../global-app-config';

export class ServiceConfigurationHelper {
  constructor(private globalAppConfig: GlobalAppConfig) {
  }

  async serviceConfiguration(): Promise<{
    packages: {
      key: string;
      price: number;
      services: { key: string; price: number, regularPrice: number }[];
    }[];
    services: { key: string; price: number, regularPrice: number }[];
  }> {
    if (!this.globalAppConfig.serviceConfig) {
      return {
        packages: [],
        services: [],
      };
    }

    const packageConfigs = this.globalAppConfig.serviceConfig.packages || [];
    const serviceConfigs = this.globalAppConfig.serviceConfig.services || [];

    const packages = packageConfigs.map((config) => {
      const services = config.services
        .map(
          (serviceKey) =>
            serviceConfigs.find((c) => c.key === serviceKey) || null
        )
        .filter((s) => s) as { key: string; price: number, regularPrice: number }[];

      return {
        key: config.key,
        price: config.price,
        services,
      };
    });

    const servicesFoundInPackages = packageConfigs
      .map((pc) => pc.services)
      .reduce((acc, pkg) => acc.concat(pkg), []);

    const services = serviceConfigs.filter(
      (service) => !servicesFoundInPackages.find((s) => s == service.key)
    );

    return {
      packages,
      services,
    };
  }
}
