import {Component, Input, OnInit} from '@angular/core';
import {Booking} from '../../domain/booking/booking';
import {BookingStoreService} from '../../domain/booking/booking-store.service';
import {RbRoutingService} from '../../core/services/rb-routing.service';

@Component({
  selector: 'agreements-sign-verdict',
  templateUrl: './agreements-sign-verdict.component.html'
})
export class AgreementsSignVerdictComponent implements OnInit {
  booking!: Booking | null;

  @Input()
  text = '';

  @Input()
  subTitle = '';

  @Input()
  description = '';

  @Input()
  showNavToStartPageBtn = true;

  constructor(private bookingStoreService: BookingStoreService,
              private rbRoutingService: RbRoutingService) {
  }

  ngOnInit(): void {
    this.booking = this.bookingStoreService.get();
  }

  navigateToMyBooking(): void {
    this.rbRoutingService.navToBooking(this.booking);
  }
}
