import {RikData} from '../rik-data';

export class Location {
  readonly latitude: string;
  readonly longitude: string;
  readonly google_place_id: string;

  constructor(data: any) {
    const rikData = new RikData(data);
    this.latitude = rikData.requireExists('latitude');
    this.longitude = rikData.requireExists('longitude');
    this.google_place_id = rikData.requireExists('google_place_id');
  }
}
