import {RikData} from '../rik-data';
import {Booking} from './booking';
import {Campaign} from '../campaign/campaign';

export class MyBooking {
  readonly booking: Booking;
  readonly near: Booking[];
  readonly ical: string | null;
  readonly campaign: Campaign;

  constructor(data: any) {
    const rikData = new RikData(data);
    this.booking = rikData.getObject('booking', Booking);
    this.near = rikData.getArrayObject('near', Booking);
    this.ical = rikData.getString('ical');
    this.campaign = rikData.getObject('campaign', Campaign);
  }

  static empty(): MyBooking {
    return new MyBooking({booking: null, near: null, ical: null, campaign: null});
  }
}
