import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../../core/services/api.service';
import {map} from 'rxjs/operators';
import {MyBooking} from "./my-booking";

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private endpoint = '/workshopinfo/v1/my-booking/';

  constructor(private apiService: ApiService) {}

  public get(
    key: string,
    showLoader = false,
    addParam = false
  ): Observable<MyBooking> {
    let url = this.endpoint + `by-key/${key}`;
    if (addParam) {
      url = `${url}?my-booking=true`;
    }
    return this.apiService
      .get<MyBooking>(url, null, showLoader)
      .pipe(map((myBooking) => new MyBooking(myBooking)));
  }

  public bulkServiceChange(
    bookingUUID: string,
    serviceStates: { [key: string]: boolean }
  ): Observable<MyBooking> {
    const data: { [key: string]: boolean } = {};

    for (const key in serviceStates) {
      const changeProperty = this.mapServiceKey(key);
      data[changeProperty] = serviceStates[key];
    }

    return this.changes(bookingUUID, data);
  }

  private mapServiceKey(serviceKey: string): string {
    const map: { [key: string]: string } = {
      WIPERS: 'job.extra_wipers',
      WASHER_FLUID: 'job.extra_washer_fluid',
      GLASS_TREATMENT: 'job.extra_glass_treatment',
      COURTESY_VEHICLE_INSURANCE: 'job.extra_courtesy_vehicle_insurance',
    };

    return map[serviceKey];
  }

  /**
   * Ex data: { "job.extra_wipers": true, "job.extra_washer_fluid": false, "job.extra_courtesy_vehicle_insurance": true }
   */
  public changes(bookingUUID: string, data: any): Observable<MyBooking> {
    return this.apiService.post(
      `workshopinfo/v1/my-booking/${bookingUUID}/changes`,
      JSON.stringify(data)
    );
  }

  public sendMessage(
    key: string,
    message: string,
    email: string
  ): Observable<any> {
    return this.apiService.post(
      `workshopinfo/v1/my-booking/${key}/user-message`,
      JSON.stringify({ message, email })
    );
  }
}
