import {RikData} from '../rik-data';
import {Title} from '../title';

export class MyVehicle {
  readonly registration_no: string;
  readonly brand: string;
  readonly model: string;
  readonly model_year: string;
  readonly description: string;

  constructor(data: any) {
    const rikData = new RikData(data);
    this.registration_no = rikData.requireExists('registration_no');
    this.brand = rikData.requireExists('brand');
    this.model = rikData.requireExists('model');
    this.model_year = rikData.requireExists('model_year');
    this.description = rikData.requireExists('description');
  }

  getSummary(): string {
    return this.registration_no ? Title.of(this.registration_no, this.brand + ' ' + this.model).text : this.description;
  }
}
