import {Booking} from '../booking/booking';
import {AgreementType} from './agreement-type';

export class AgreementHelper {
  public static getAgreementTypesToSign(booking: Booking): AgreementType[] {
    const agreementTypes: AgreementType[] = [];
    if (!booking.canSignAgreements) {
      return agreementTypes;
    }

    if (booking.canSignCvAgreement) {
      agreementTypes.push(AgreementType.COURTESY_VEHICLE);
    }

    if (booking.canSignClaimAgreement) {
      agreementTypes.push(AgreementType.INSURANCE_CLAIM);
    }

    return agreementTypes;
  }
}

