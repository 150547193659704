<div class="my-booking-page" data-cy="my_booking_page" *ngIf="booking">
  <h2 translate>MY_BOOKING</h2>

  <mybooking-summary class="mb-40pt" [booking]="booking" [ical]="ical"></mybooking-summary>

  <mybooking-info-box [booking]="booking"></mybooking-info-box>

  <div *ngIf="booking.isConfirmedOrPrev()">
    <h2 translate>SAVE_TIME_BEFORE_VISIT</h2>
    <div class="info-section" *ngIf="booking.canSignAgreements">
      <highlighted-action class="clickable" title="AGREEMENTS_SIGN_TITLE" buttonText="AGREEMENT_SIGN_IT"
                          data-cy="nav_to_insurance_claim" (click)="navigateToInsuranceClaim()"></highlighted-action>
      <p *ngIf="booking.canSignAllAgreements" translate>AGREEMENTS_TO_SIGN_CV_AND_INSURANCE_CLAIM</p>
      <p *ngIf="booking.canOnlySignInsuranceClaim" translate>AGREEMENTS_TO_SIGN_INSURANCE_CLAIM</p>
      <p *ngIf="booking.canOnlySignCourtesyVehicle" translate>AGREEMENTS_TO_SIGN_CV</p>
    </div>

    <services-section></services-section>

    <div *ngIf="showPayment" data-cy="payment_section">
      <highlighted-action class="clickable" title="PAYMENT_SELECT" buttonText="PAYMENT_KLARNA"
                          (click)="navigateToPayment()"></highlighted-action>
      <div class="info-section">
        <p translate>MYBOOKING.FOOTER.PAYMENT_OPTION_1</p>
        <p translate>MYBOOKING.FOOTER.PAYMENT_OPTION_2</p>
      </div>
    </div>
  </div>


  <related-bookings></related-bookings>

  <div class="footer">
    <div *ngIf="!showPayment && !isPaymentCompleted" translate>
      MYBOOKING.FOOTER.PAYMENT_OPTION_1
    </div>
    <div *ngIf="!showPayment && !isPaymentCompleted" translate>
      MYBOOKING.FOOTER.PAYMENT_OPTION_2
    </div>
    <div [innerHtml]="'MYBOOKING.FOOTER.QUESTIONS' | translate: { phone: phone }"></div>
  </div>
