import { Component, Input, OnInit } from '@angular/core';
import { Booking } from '../../../../../domain/booking/booking';
import { RbRoutingService } from '../../../../../core/services/rb-routing.service';
import { BookingStoreService } from 'src/app/domain/booking/booking-store.service';

@Component({
  selector: 'related-bookings',
  templateUrl: './related-bookings.component.html',
})
export class RelatedBookingsSectionComponent implements OnInit {
  relatedBookings: Booking[] = [];

  constructor(
    private rbRoutingService: RbRoutingService,
    private bookingStoreService: BookingStoreService
  ) {}

  ngOnInit(): void {
    this.relatedBookings = this.bookingStoreService.getNear();
  }

  navToNear(booking: Booking): void {
    this.rbRoutingService.navToBooking(booking);
  }
}
