import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {Service} from '../../../../../domain/services/service';
import {BookingScopedService} from '../../../../../domain/booking/booking-scoped.service';

@Component({
  selector: 'service-selection',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './service-selection.component.html',
})
export class ServiceSelectionComponent {

  constructor(private booking: BookingScopedService) {
  }

  private temp = false;

  @Input()
  public service: Service = Service.placeholder();

  @HostBinding('class.editable')
  get isEditable(): boolean {
    return !this.booking.loading;
  }

  get selected(): boolean {
    return this.service.selected;
  }

  clicked(): void {
    if (this.booking.loading) {
      return;
    }

    this.booking.toggleService(this.service)
      .subscribe();
  }
}
