import {TranslateService} from '@ngx-translate/core';

export class Title {

  get text(): string {
    return this.textWith(', ');
  }

  private static get notNullOrEmpty(): (value: string, index: number, arr: Array<any>) => any {
    return (value, index, arr) => value && (value.length > 0);
  }

  private parts: Array<string> = [];

  public static of(...parts: Array<string>): Title {
    const title = new Title();
    title.parts = parts.filter(Title.notNullOrEmpty);
    return title;
  }

  public static fromArray(parts: Array<string>): Title {
    const title = new Title();
    title.parts = parts.filter(Title.notNullOrEmpty);
    return title;
  }

  public static convert(candidate: Title | string | Array<string>): Title {
    if (candidate instanceof Title) {
      return candidate;
    }

    if (candidate instanceof Array) {
      return Title.fromArray(candidate as Array<string>);
    }

    return Title.of(candidate as string);
  }

  public translateWith(translateService: TranslateService): void {
    this.parts = this.parts.map(p => translateService.instant(p));
  }

  public textWith(separator: string): string {
    return this.parts
      .join(separator);
  }

  public toString(): string {
    return this.text;
  }
}
