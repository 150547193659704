import {Component, OnInit} from '@angular/core';
import {Booking} from '../../../domain/booking/booking';
import {BookingStoreService} from '../../../domain/booking/booking-store.service';
import {RbRoutingService} from '../../../core/services/rb-routing.service';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  templateUrl: './workshop-details-page.component.html',
})
export class WorkshopDetailsPageComponent implements OnInit {
  booking: Booking | null = null;
  extrasText = '';

  constructor(private route: ActivatedRoute,
              private bookingStoreService: BookingStoreService,
              private translateService: TranslateService,
              private rbRoutingService: RbRoutingService) {
  }

  ngOnInit(): void {
    this.booking = this.bookingStoreService.get();
    this.extras();
  }

  back(): void {
    this.rbRoutingService.navParent(this.route);
  }

  extras(): void {
    if (!this.booking) {
      return;
    }
    const title = this.booking.extrasSummary;
    title.translateWith(this.translateService);
    this.extrasText = title.text;
  }
}
