import {Component, OnInit} from '@angular/core';

@Component({
  templateUrl: './agreements-sign-reject-page.component.html'
})
export class AgreementsSignRejectPageComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
