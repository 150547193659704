<header>
  <div class="check-yes">
    <rb-checkbox class="clickable" [checked]="package.selected" (click)="clicked()"></rb-checkbox>
    <div class="yesplease clickable" [innerHtml]="package.translations.yes" (click)="clicked()"></div>
  </div>
  <div class="price" [innerHtml]="package.translations.price"></div>
  <div class="description" [innerHtml]="package.translations.description"></div>
</header>
<div class="services">
  <service-selection [service]="service" *ngFor="let service of package.services; trackBy: serviceTracker" ></service-selection>
</div>
