import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProgressService {
  active$ = new BehaviorSubject(false);

  activate(): void {
    this.active$.next(true);
  }

  deActivate(): void {
    this.active$.next(false);
  }
}
