import {Inject, Injectable} from '@angular/core';
import {GLOBAL_APP_CONFIG} from './global-app-config.token';

@Injectable()
export class ConfigurationService {
  [key: string]: any;

  constructor(@Inject(GLOBAL_APP_CONFIG) private config: any) {
    console.log('Global Configuration', config);
    return new Proxy(this, this);
  }

  public get(target: any, prop: string): any {
    return target.resolve(prop);
  }

  // noinspection JSMethodCanBeStatic
  private resolve(field: string): any {
    return this.config[field];
  }
}
