import {Booking} from '../booking/booking';

export class Service {
  selected = false;

  constructor(readonly key: string, public price: number, readonly translations: ServiceTranslations) {
  }

  get effectivePrice(): number {
    if (!this.selected) {
      return 0;
    }

    return this.price;
  }

  offeredForBooking(booking: Booking): boolean {
    if (this.key === 'COURTESY_VEHICLE_INSURANCE') {
      return booking.hasCourtesyVehicle();
    }

    return true;
  }

  init(booking: Booking): void {
    this.selected = booking.isSelected(this.key);
  }

  static placeholder(): Service {
    return new Service('', 0, {yes: '', price: ''});
  }
}

interface ServiceTranslations {
  yes: string;
  price: string;
}
