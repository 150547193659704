import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {ErrorHelper} from './error-helper';
import {ApiService} from '../services/api.service';
import {APP_ROUTES} from '../../app-routes';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  handleError(error: Error | HttpErrorResponse): void {
    const router = this.injector.get(Router);
    const ngZone = this.injector.get(NgZone);

    let message = '';
    let title = 'ERROR_TITLE';
    let stack: any;

    if (error instanceof HttpErrorResponse) {
      message = ErrorHelper.getServerMessage(error);
      if (!message) {
        title = 'ERROR_TITLE_FATAL';
        message = 'ERROR_API_UNKNOWN';
        stack = ErrorHelper.getServerStack(error);
        this.postToBackend(error, message, stack, 'RydsMyBooking_ServerError').then(/* */);
      }
    } else {
      title = 'ERROR_TITLE_FATAL';
      this.postToBackend(error, message, '', 'RydsMyBooking_ClientError').then(/* */);
    }

    ngZone.run(() =>
      router.navigate([APP_ROUTES.ERROR_PAGE], {state: {title, message}}).then()
    );
  }

  private postToBackend(error: any, message: string, stackTrace: string, context: string): Promise<any> {
    const apiService = this.injector.get(ApiService) as ApiService;
    if (apiService) {
      return apiService.postErrorUnlessLocal(error, message, stackTrace, context).catch(
        (e) => console.log('Error post failed: ', e)
      );
    } else {
      console.error('RydsGlobalErrorHandler - No ApiService');
      return Promise.reject('No ApiService could be found.');
    }
  }
}
