import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {KlarnaClientData} from './klarna-client-data';
import {KlarnaAuthorization} from './klarna-authorization';
import {EnvironmentService} from '../../core/services/environment.service';
import {LogService} from '../../core/services/log-service';
import {IHash} from '../../domain/i-hash';

@Injectable({providedIn: 'root'})
export class KlarnaApiService {

  constructor(private httpClient: HttpClient, private environmentService: EnvironmentService, private log: LogService) {
  }

  init(bookingUuid: string): Observable<KlarnaClientData> {
    const url = `${this.environmentService.apiEndpoint}/workshopinfo/v1/klarna/${bookingUuid}/init-my-booking`;
    return this.httpClient.post(url, null, {
      headers: this.headers(this.environmentService.jwtToken)
    }).pipe(
      map((data: any) => {
          this.log.info('KlarnaApiService.init() response:', data);
          return data;
        },
      ),
      catchError((error) => {
          return of(error);
        }
      ));
  }

  complete(bookingUuid: string, authorization: KlarnaAuthorization): Observable<any> {
    const url = `${this.environmentService.apiEndpoint}/workshopinfo/v1/klarna/${bookingUuid}/complete`;
    return this.httpClient.post(url, authorization, {
      headers: this.headers(this.environmentService.jwtToken)
    }).pipe(
      map((data: any) => {
          this.log.info('KlarnaApiService.complete() response:', data);
          return data;
        },
      ),
      catchError((error) => {
          return of(error);
        }
      ));
  }

  private headers(token: string | null): HttpHeaders {
    const headers: IHash<string> = {
      'Content-Type': 'application/json',
    };

    if (token) {
      headers.Authorization = ['Bearer', token].join(' ');
    }
    return new HttpHeaders(headers);
  }
}
