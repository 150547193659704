import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'rb-switch',
  template: `
    <div class="switch-component"
         (click)="toggle()"
         [class.switch-on]="control?.value"
         [class.switch-off]="!control?.value"
         [class.switch-disabled]="control?.disabled">
      <span class="state">{{(control?.value ? 'YES' : 'NO') | translate}}</span>
      <span class="state-bullet"></span>
    </div>
  `,
  styleUrls: ['./switch.scss']
})
export class SwitchComponent {
  @Input()
  control!: AbstractControl | null;

  toggle(): void {
    if (this.control && !this.control.disabled) {
      this.control.setValue(!this.control.value);
      this.control.markAsDirty();
    }
  }
}
