import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IHash} from '../../domain/i-hash';
import {EnvironmentService} from './environment.service';
import {LocationStrategy} from '@angular/common';
import {finalize, map} from 'rxjs/operators';
import {ProgressService} from './progress.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient,
              private location: LocationStrategy,
              private progressService: ProgressService,
              private environmentService: EnvironmentService) {
  }

  public get<T>(url: string, urlParams: any = null, showLoader = false): Observable<T> {
    if (showLoader) {
      this.progressService.activate();
    }
    return this.http.get<T>(this.makeUrl(url), {
      observe: 'response',
      headers: this.headers(this.environmentService.jwtToken),
      params: urlParams
    }).pipe(
      finalize(() => this.progressService.deActivate()),
      map((res: any) => res.body));
  }

  public post<T>(url: string, body: {} = {}): Observable<T> {
    return this.http.post<T>(this.makeUrl(url), body, {
      observe: 'response',
      headers: this.headers(this.environmentService.jwtToken)
    }).pipe(map((res: any) => res.body));
  }

  public postBlob<T>(url: string, body: {} = {}): Observable<T> {
    return this.http.post<T>(this.makeUrl(url), body, {
      headers: this.headers(this.environmentService.jwtToken),
      responseType: 'blob' as 'json',
    });
  }

  public postErrorUnlessLocal(error: any, message: string, stackTraceOrUrl: string, context: string): Promise<any> {
    if (this.environmentService.isLocal) {
      console.info('Not posting error in LOCAL env. Would have posted: \n', error, '\n', context, '\n', stackTraceOrUrl);
      return Promise.reject('Errors are not posted in LOCAL env');
    }
    const route = this.location.path(true);
    const body = {
      context,
      message,
      env: this.environmentService.label,
      route,
      error,
      stackTrace: stackTraceOrUrl
    };
    return this.http.post(this.makeUrl('web-booking/log'), JSON.stringify(body),
      {headers: this.headers(this.environmentService.jwtToken)}).toPromise();
  }

  private headers(token: string | null): HttpHeaders {
    const headers: IHash<string> = {
      'Content-Type': 'application/json',
    };

    if (token) {
      headers.Authorization = ['Bearer', token].join(' ');
    }
    return new HttpHeaders(headers);
  }

  private makeUrl(url: string): string {
    return this.makeUrlWithApiEndpoint(this.environmentService.apiEndpoint, url);
  }

  private makeUrlWithApiEndpoint(endpoint: string, url: string): string {
    if (url.startsWith('http')) {
      return url;
    }
    return [endpoint, url].join('/').replace(/([^:]\/)\/+/g, '$1');
  }
}
