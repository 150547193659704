<highlighted-heading class="services" heading="EXTRA_PRE_ORDER" *ngIf="show"></highlighted-heading>
<div class="packages">
  <package-card [package]="serviceConfiguration.package"
                *ngIf="serviceConfiguration.package"></package-card>
</div>
<div class="services" *ngIf="serviceConfiguration.services.length > 0">
  <service-selection [service]="service" *ngFor="let service of serviceConfiguration.services"></service-selection>
</div>
<hr *ngIf="serviceConfiguration.services.length > 0"/>
<div class="services-total" *ngIf="show">
  <div class="heading" [innerHtml]="'MYBOOKING.SERVICES.TOTAL.HEADING' | translate "></div>
  <div class="total" [innerHtml]="'MYBOOKING.SERVICES.TOTAL.TOTAL' | translate: { price: serviceConfiguration.total } "></div>
</div>
