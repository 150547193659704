import { NgModule } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { GlobalConfigService } from './global-config.service';
import { GLOBAL_APP_CONFIG } from './tokens';

@NgModule({
    providers: [
        ConfigurationService,
        GlobalConfigService,
        { provide: GLOBAL_APP_CONFIG, useValue: globalAppConfig }
    ]
})
export class OldConfigurationModule {}
