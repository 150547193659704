import {Directive, ElementRef, Input, OnInit, OnDestroy} from '@angular/core';

@Directive({
  selector: '[rbFadeIn]'
})
export class FadeInDirective implements OnInit, OnDestroy {

  @Input('rbFadeIn')
  _inputAnimationTime: string | undefined = '500';

  private _timeout = 0;

  constructor(private _el: ElementRef) {
  }

  ngOnInit(): void {
    this._el.nativeElement.style.opacity = '0';
    this._el.nativeElement.style.transition = 'all ' + this._inputAnimationTime + 'ms ease-in-out';
    this._timeout = setTimeout(() => {
      this._el.nativeElement.style.opacity = '1';
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this._timeout);
  }
}
