import {Injectable} from '@angular/core';
import {Booking} from './booking';
import {Campaign} from "../campaign/campaign";
import {MyBooking} from "./my-booking";

@Injectable({
  providedIn: 'root'
})
export class BookingStoreService {
  private myBooking: MyBooking | null = null;

  public set(myBooking: MyBooking | null): void {
    this.myBooking = myBooking != null ? new MyBooking(myBooking) : null;
  }

  public getMyBooking(): MyBooking | null {
    return this.myBooking;
  }

  public get(): Booking | null {
    return this.myBooking?.booking ?? null;
  }

  public getCampaign(): Campaign | null {
    return this.myBooking?.campaign ?? null;
  }

  public getNear(): Booking[] {
    return this.myBooking?.near ?? [];
  }

  public getIcal(): string | null {
    return this.myBooking?.ical ?? null;
  }
}
