<form [formGroup]="form" autocomplete="off" *ngIf="form && booking">
  <h4 class="mb-40">
    <div class="mb-10" *ngIf="booking.canSignClaimAgreement">{{'INSURANCE_CLAIM' | translate}}</div>
    <div class="mb-10" *ngIf="booking.canSignCvAgreement">{{'AGREEMENT_COURTESY_VEHICLE' | translate}}</div>
  </h4>
  <div *ngIf="booking.canSignClaimAgreement" style="margin-bottom: 10px;">
    <div class="row" style="margin-top: 5px;">
      <div class="col-12">
        <form-input [form]="form" [ctrlName]="fName.insurance_holder_name"
                    label="AGREEMENT_INSURANCE_HOLDER_NAME"></form-input>
      </div>
      <div class="col-12 col-sm-6" *ngIf="!orgnoHidden">
        <form-input [form]="form" [ctrlName]="fName.insurance_holder_org_no" [inputMask]="orgNoMask()"
                    label="AGREEMENT_INSURANCE_HOLDER_ORG_NO"></form-input>
      </div>
      <div class="col-12" *ngIf="orgnoHidden">
        <input type="hidden" [formControlName]="fName.insurance_holder_org_no">
      </div>
      <div class="col-12 col-sm-6">
        <div class="form-group">
          <label>{{'CUSTOMER_VAT' | translate}}</label>
          <div class="form-custom-element" data-cy="insurance_holder_vat_subject">
            <rb-switch [control]="form.get(fName.insurance_holder_vat_subject)"></rb-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form-input [form]="form" [ctrlName]="fName.insurance_reason" label="AGREEMENT_INSURANCE_REASON"></form-input>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 title-divider" *ngIf="booking.canSignClaimAgreement">
      <h4>{{'AGREEMENT_SIGN' | translate}}</h4>
      <hr>
    </div>
    <div class="col-12" *ngIf="booking.canSignClaimAgreement">
      <div class="form-group">
        <label>{{ "AGREEMENT_SIGN_BY_TYPE" | translate }}</label>
        <button-radio-group [formControlName]="fName.sign_by_type"
                            [options]="signBy" [textBeforeCircle]="false"></button-radio-group>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6">
      <form-input [form]="form" [ctrlName]="fName.signee_name"
                  label="AGREEMENT_SIGNEE_NAME"></form-input>
    </div>
    <div class="col-12 col-sm-6">
      <form-input [form]="form" [ctrlName]="fName.signee_phone"
                  label="AGREEMENT_SIGNEE_PHONE" [inputMask]="phoneMask()"></form-input>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form-input [form]="form" [ctrlName]="fName.email"
                  label="AGREEMENT_EMAIL"></form-input>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div *ngIf="!offerCvInsurance()">
        <button type="button" [disabled]="createInProgress" class="btn mt-20" (click)="create()">
          {{"AGREEMENT_SEND" | translate}}
          <i *ngIf="createInProgress" class="fa fa-spinner fa-spin"></i>
        </button>
      </div>
      <div *ngIf="offerCvInsurance()">
        <button type="button" class="btn btn-large mt-20" data-cy="next" (click)="next()">
          {{"NEXT" | translate}}
          <i *ngIf="createInProgress" class="fa fa-spinner fa-spin"></i>
        </button>
      </div>
    </div>
  </div>
</form>
