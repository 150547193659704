<collapsible-box class="mybooking-info" [heading]="'MYBOOKING.INFO.HEADING' | translate"
                 [description]="'MYBOOKING.INFO.DESCRIPTION' | translate"
                 [open]="booking.hasArrived || booking.isCancelled">
  <rb-grid>
    <rb-grid-row key="DATE" [value]="booking.displayReservedDate">
    </rb-grid-row>
    <rb-grid-row key="STATUS" [value]="booking.state.code"></rb-grid-row>
    <rb-grid-row key="TIME" [value]="arrivalTimeExplanation"
                 *ngIf="!booking.hasArrived && !booking.isCancelled && !booking.isDropIn && booking.isReadyForDepartureOrPrev()">
    </rb-grid-row>
    <rb-grid-row key="JOB_TYPE" [value]="booking.type"></rb-grid-row>
    <rb-grid-row key="WORKSHOP">
      <a (click)="navigateToWorkshopDetails()">{{booking.workshop.name}}</a>
    </rb-grid-row>
    <rb-grid-row key="VEHICLE" [value]="booking.vehicle.getSummary()"></rb-grid-row>
    <rb-grid-row key="INSURANCE" [value]="booking.insurance_company"></rb-grid-row>
    <rb-grid-row key="EXTRA_TITLE" [value]="extrasText" *ngIf="showInfoSectionExtras"></rb-grid-row>
    <rb-grid-row key="COURTESY_VEHICLE" *ngIf="booking.hasCourtesyVehicle()">
      {{booking.courtesy_vehicle_type | translate}}
      <div [innerHTML]="'COURTESY_VEHICLE_INFO' | translate"></div>
    </rb-grid-row>
    <rb-grid-row key="PAYMENT" [value]="booking.klarna_status" *ngIf="isPaymentCompleted"></rb-grid-row>
    <rb-grid-row key="AGREEMENT" *ngIf="booking.agreement_link">
      {{'AGREEMENT_SIGNED' | translate}}<br/>
      <a [href]="booking.agreement_link" download>{{
          'AGREEMENT_DOWNLOAD' | translate
        }}</a>
    </rb-grid-row>
  </rb-grid>

  <div class="row" *ngIf="booking.isConfirmedOrPrev()">
    <div class="col-12">
      <div class="flex-center mt-30">
        <button type="button" class="btn btn-large" (click)="rebook()">
          {{"BOOKING.REBOOK.TITLE" | translate}}
        </button>
      </div>
    </div>
  </div>
</collapsible-box>
