import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AgreementData} from './agreement-data';
import {Uuid} from '../uuid';
import {ApiService} from '../../core/services/api.service';
import {CreateAgreementResponse} from './create-agreement-response';
import {MyBooking} from "../booking/my-booking";

@Injectable({
  providedIn: 'root'
})
export class AgreementService {
  constructor(private apiService: ApiService) {
  }

  public create(bookingUuid: Uuid, data: AgreementData): Observable<CreateAgreementResponse> {
    const url = `workshopinfo/v1/my-booking/${bookingUuid}/agreement-create`;

    return this.apiService.post<CreateAgreementResponse>(url, JSON.stringify(data));
  }

  public signed(bookingUuid: Uuid): Observable<MyBooking> {
    const url = `workshopinfo/v1/my-booking/${bookingUuid}/agreement-signed?my-booking=true`;

    return this.apiService.post<MyBooking>(url, {});
  }

  public preview(bookingUuid: Uuid, data: AgreementData): Observable<any> {
    const url = `workshopinfo/v1/my-booking/${bookingUuid}/agreement-preview`;

    return this.apiService.postBlob(url, JSON.stringify(data));
  }
}
