import {Component, OnInit} from '@angular/core';
import {Booking} from '../../../domain/booking/booking';
import {RbRoutingService} from '../../../core/services/rb-routing.service';
import {ActivatedRoute} from '@angular/router';
import {BookingStoreService} from '../../../domain/booking/booking-store.service';
import {BookingService} from '../../../domain/booking/booking.service';
import {finalize} from 'rxjs/operators';

@Component({
  templateUrl: './payment-page.component.html',
})
export class PaymentPageComponent implements OnInit {
  booking: Booking | null = null;
  private isInProgress = false;
  private isKlarnaInProgress = false;

  constructor(private rbRoutingService: RbRoutingService,
              private route: ActivatedRoute,
              private bookingService: BookingService,
              private bookingStoreService: BookingStoreService) {
  }

  ngOnInit(): void {
    this.booking = this.bookingStoreService.get();
    this.rbRoutingService.routeGuardConfirmed(this.booking);
  }

  private updateBooking(): void {
    if (!this.booking) {
      return;
    }

    this.isInProgress = true;
    this.bookingService.get(this.booking.key).pipe(
      finalize(() => this.isInProgress = false)
    ).subscribe(myBooking => {
      this.bookingStoreService.set(myBooking);
    });
  }

  navigateToMyBooking(): void {
    if (this.isAnythingInProgress()) {
      return;
    }
    this.rbRoutingService.navParent(this.route);
  }

  isAnythingInProgress(): boolean {
    return this.isInProgress || this.isKlarnaInProgress;
  }

  onApproved(isApproved: boolean): void {
    if (isApproved) {
      this.updateBooking();
    }
  }

  onKlarnaInProgress(isInProgress: boolean): void {
    this.isKlarnaInProgress = isInProgress;
  }
}
