<label *ngFor="let option of opts" class="radio-group" [ngClass]="{'flex-space-between': displayTextBeforeCircle()}" data-cy="option">
  <div *ngIf="displayTextBeforeCircle()">
    <span class="radio-title" *ngIf="option.preText">{{option.preText | translate}}</span>
    <span *ngIf="option.preText && option.text">&nbsp;-&nbsp;</span>
    <span *ngIf="option.text">{{option.text | translate}}</span>
  </div>
  <input type="radio" [value]="option.value" (click)="onSelect(option.value)"/>
  <div class="outer-circle">
    <div class="inner-circle" *ngIf="option.value === selectedValue"></div>
  </div>
  <div *ngIf="!displayTextBeforeCircle()" class="ml-30">
    <span class="radio-title" *ngIf="option.preText">{{option.preText | translate}}</span>
    <span class="radio-title" *ngIf="option.preText && option.text">&nbsp;-&nbsp;</span>
    <span *ngIf="option.text">{{option.text | translate}}</span>
  </div>
</label>
