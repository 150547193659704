<div class="background-secondary" data-cy="payment_page">
  <div class="container" style="max-width: 430px;">
    <page-title [showArrow]="false" text="PAYMENT" [disabled]="isAnythingInProgress()"
                (backChange)="navigateToMyBooking()"></page-title>
    <div *ngIf="booking">
      <klarna [bookingUuid]="booking.uuid"
              [bookingEmail]="booking.contact_email"
              (inProgressChange)="onKlarnaInProgress($event)"
              (approvedChange)="onApproved($event)">
      </klarna>
    </div>
  </div>
</div>
