import {Directive, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
  selector: 'a[caryFileUrl]'
})
export class FileUrlDirective implements OnChanges {

  @Input()
  caryFileUrl: string | null = '';
  @Input()
  mediaType = 'text/plain';

  constructor(private el: ElementRef<HTMLAnchorElement>, private renderer: Renderer2) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const url = FileUrlDirective.createUrl(this.caryFileUrl, this.mediaType);
    this.renderer.setAttribute(this.el.nativeElement, 'href', url);
  }

  private static createUrl(data: string | null, mediaType: string): string | '' {
    if (!data) {
      return '';
    }

    const base64 = btoa(data);
    return `data:${mediaType};base64,${base64}`;
  }
}
