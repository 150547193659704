import {Directive, HostBinding, Input, OnInit} from '@angular/core';
import {EnvironmentService} from '../../core/services/environment.service';

@Directive({
  selector: 'a[rebook]'
})
export class RebookUrlDirective implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('rebook')
  bookingKey = '';

  @HostBinding('attr.href')
  hrefAttr = '';

  constructor(private environment: EnvironmentService) {
  }

  ngOnInit(): void {
    const url = this.environment.url('webBooking');
    this.hrefAttr = `${url}/rebook/${this.bookingKey}`;
  }
}
