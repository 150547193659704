import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'button-radio-group',
  templateUrl: './button-radio-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonRadioGroupComponent),
      multi: true
    }
  ]
})
export class ButtonRadioGroupComponent implements ControlValueAccessor {
  selectedValue!: any;
  opts: ButtonRadioGroupOption[] = [];

  @Input() disabled = false;

  @Input() set options(value: string[]) {
    if (value) {
      this.opts = this.stringArrToObjArr(value);
    }
  }

  @Input() set optionsObj(value: ButtonRadioGroupOption[]) {
    if (value) {
      this.opts = value;
    }
  }

  @Input() textBeforeCircle = true;

  constructor() {
  }

  onChange = (val: string) => {
  }

  onTouched = () => {
  }

  stringArrToObjArr(options: string[]): ButtonRadioGroupOption[] {
    return options.map(opt => {
      return {preText: opt, text: null, value: opt};
    });
  }

  writeValue(option: string | number | boolean): void {
    this.selectedValue = option;
    this.onChange(this.selectedValue);
  }

  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onSelect(option: string | number | boolean): void {
    if (!this.disabled) {
      this.writeValue(option);
    }
  }

  displayTextBeforeCircle(): boolean {
    return this.textBeforeCircle;
  }
}

export interface ButtonRadioGroupOption {
  value: string | number | boolean;
  text: string | null;
  preText: string | null;
}
