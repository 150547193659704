import {AfterViewInit, Component, Input} from '@angular/core';
import {KlarnaTypes} from './klarna-types';
import {LogService} from '../../core/services/log-service';

declare let Klarna: KlarnaTypes;

@Component({
  selector: 'klarna-payment-option',
  templateUrl: './klarna-payment-option.component.html',
  styleUrls: ['./klarna-payment-option.component.scss']
})
export class KlarnaPaymentOptionComponent implements AfterViewInit {
  @Input()
  paymentCategory: any;

  constructor(private log: LogService) {
  }

  ngAfterViewInit(): void {
    this.log.info('ngAfterViewInit');
    const identifier = this.paymentCategory.identifier;
    Klarna.Payments.load({
      container: `#klarna-payments-${identifier}`,
      payment_method_category: identifier
    }, (res: any) => {
      this.log.info('load res', res);
    });
  }
}
