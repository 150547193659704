import {Component, OnInit} from '@angular/core';
import {EnvironmentService} from '../../../core/services/environment.service';

@Component({
  templateUrl: './not-found-page.component.html'
})
export class NotFoundPageComponent implements OnInit {

  constructor(private environmentService: EnvironmentService) {
  }

  ngOnInit(): void {
  }

  navToHomepage(): void {
    window.location.href = this.environmentService.homepageUrl;
  }
}
