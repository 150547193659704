import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MyBookingRoutingModule} from './my-booking-routing.module';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {MyBookingPageComponent} from './my-booking-page/my-booking-page.component';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {PaymentPageComponent} from './payment-page/payment-page.component';
import {ReactiveFormsModule} from '@angular/forms';
import {WorkshopDetailsPageComponent} from './workshop-details-page/workshop-details-page.component';
import {RebookPageComponent} from './rebook-page/rebook-page.component';
import {AgreementsSignSuccessPageComponent} from './agreements-page/pages/agreements-sign-success-page/agreements-sign-success-page.component';
import {AgreementsSignRejectPageComponent} from './agreements-page/pages/agreements-sign-reject-page/agreements-sign-reject-page.component';
import {AgreementsSignErrorPageComponent} from './agreements-page/pages/agreements-sign-error-page/agreements-sign-error-page.component';
import {AgreementsPageComponent} from './agreements-page/agreements-page.component';
import {AgreementsCourtesyVehicleExtraPageComponent} from './agreements-page/pages/agreements-courtesy-vehicle-extra-page/agreements-courtesy-vehicle-extra-page.component';
import { AgreementsSignProcessingPageComponent } from './agreements-page/pages/agreements-sign-processing-page/agreements-sign-processing-page.component';



@NgModule({
  declarations: [
    AgreementsCourtesyVehicleExtraPageComponent,
    AgreementsPageComponent,
    AgreementsSignSuccessPageComponent,
    AgreementsSignErrorPageComponent,
    AgreementsSignRejectPageComponent,
    LandingPageComponent,
    WorkshopDetailsPageComponent,
    PaymentPageComponent,
    RebookPageComponent,
    AgreementsSignProcessingPageComponent,
  ],
  imports: [
    CommonModule,
    MyBookingRoutingModule,
    SharedModule,
    TranslateModule,
    ReactiveFormsModule
  ]
})
export class MyBookingModule {
}
