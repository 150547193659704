import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BookingService} from './booking.service';
import {Package} from '../services/package';
import {Service} from '../services/service';
import {finalize, tap} from 'rxjs/operators';
import {Booking} from './booking';
import {BookingStoreService} from "./booking-store.service";
import {MyBooking} from "./my-booking";

@Injectable({
  providedIn: 'root',
})
export class BookingScopedService {
  private _loading = false;

  constructor(private bookings: BookingService, private bookingStoreService: BookingStoreService) {
  }

  togglePackage(pack: Package): Observable<MyBooking> {
    const value = !pack.selected;

    const serviceStates = pack.services
      .filter(service => service.selected !== value)
      .reduce((all, service) => {
        all[service.key] = value;
        return all;
      }, {} as { [key: string]: boolean });

    return this.updateServices(serviceStates);
  }

  toggleService(service: Service): Observable<MyBooking> {
    return this.updateServices({
      [service.key]: !service.selected
    });
  }

  private updateServices(serviceStates: { [key: string]: boolean }): Observable<MyBooking> {
    this.startLoading();
    return this.bookings.bulkServiceChange(this.booking.uuid, serviceStates).pipe(
      tap(b => this.bookingStoreService.set(b)),
      finalize(() => this._loading = false));
  }

  get booking(): Booking {
    const myBooking = this.bookingStoreService.getMyBooking();
    if (myBooking == null || myBooking.booking == null || !myBooking.booking.uuid) {
      throw new Error("BookingScopedService: Could not get a valid booking from bookingStoreService");
    }
    return myBooking.booking;
  }

  get loading(): boolean {
    return this._loading;
  }

  private startLoading(): void {
    this._loading = true;
  }
}
