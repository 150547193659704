export enum JobType {
  Unknown = 'JOB_TYPE_UNKNOWN',
  RoofWindow = 'JOB_TYPE_ROOF_WINDOW',
  RearWindow = 'JOB_TYPE_REAR_WINDOW',
  SideWindow = 'JOB_TYPE_SIDE_WINDOW',
  Windshield = 'JOB_TYPE_WINDSHIELD',
  SunFilm = 'JOB_TYPE_SUN_FILM',
  Repair = 'JOB_TYPE_REPAIR',
  Other = 'JOB_TYPE_OTHER',
  DabAntenna = 'JOB_TYPE_DAB_ANTENNA'
}
