<mybooking-header></mybooking-header>
<div>
  <div class="container" *ngIf="inProgress">
    <h3 style="color: #222222; display: flex; position: fixed; top:130px;">LOADING...&nbsp;
      <span><i class="fa fa-spinner fa-spin"></i></span>
    </h3>
  </div>
  <div *ngIf="!inProgress" [rbFadeIn]>
    <router-outlet></router-outlet>
  </div>
</div>