export const MY_BOOKING_ROUTES = {
  AGREEMENTS_SIGN_SUCCESS: 'agreement-sign-success',
  AGREEMENTS_SIGN_PROCESSING: 'agreement-sign-processing',
  AGREEMENTS_SIGN_ERROR: 'agreement-sign-error',
  AGREEMENTS_SIGN_REJECT: 'agreement-sign-reject',
  LANDING_PAGE: 'bookings/:key',
  NOT_FOUND_PAGE: 'bookings/not-found',
  AGREEMENTS_PAGE: 'agreements',
  AGREEMENTS_COURTESY_VEHICLE_EXTRA_PAGE: 'cv',
  PAYMENT: 'payment',
  DETAILS: 'details',
  REBOOK: 'rebook'
};
