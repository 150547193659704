import {ServiceConfiguration} from '../services/service-configuration';
import {Package} from '../services/package';
import {Booking} from '../booking/booking';
import {JobType} from '../constants';
import {TranslateService} from '@ngx-translate/core';
import {Campaign} from "./campaign";

export class CampaignWrapper {

  constructor(private campaign: Campaign,
              private base: ServiceConfiguration,
              private booking: Booking,
              private translations: TranslateService) {
  }

  get serviceConfiguration(): ServiceConfiguration {
    const {services} = this.base;

    return {
      package: this.package(),
      services,
      total: 0
    };
  }

  package(): Package | null {
    const {package: base} = this.base;

    if (!base) {
      return null;
    }

    if (this.booking.type === JobType.Windshield) {
      if (!this.campaign.replace_package_enabled) {
        return null;
      }

      if (this.campaign.replace_package_price) {
        base.price = this.campaign.replace_package_price;
        base.translations.price = this.translations
          .instant('SERVICES.PACKAGE_PRICE_PRICE', {price: base.price, regularPrice: base.regularPrice});
      }

      if (this.campaign.replace_free_glass_treatment) {
        base.services.filter(s => s.key === 'GLASS_TREATMENT').forEach(service => {
          service.price = 0;
          service.translations.price = this.translations.instant('SERVICES.GLASS_TREATMENT_CAMPAIGN_FREE');
        });
      }

      if (this.campaign.replace_free_washer_fluid) {
        base.services.filter(s => s.key === 'WASHER_FLUID').forEach(service => {
          service.price = 0;
          service.translations.price = this.translations.instant('SERVICES.WASHER_FLUID_CAMPAIGN_FREE');
        });
      }

      if (this.campaign.replace_free_wipers) {
        base.services.filter(s => s.key === 'WIPERS').forEach(service => {
          service.price = 0;
          service.translations.price = this.translations.instant('SERVICES.WIPERS_CAMPAIGN_FREE');
        });
      }
    }

    if (this.booking.type === JobType.Repair) {
      if (!this.campaign.repair_package_enabled) {
        return null;
      }

      if (this.campaign.repair_package_price) {
        base.price = this.campaign.repair_package_price;
        base.translations.price = this.translations
          .instant('SERVICES.PACKAGE_PRICE_PRICE', {price: base.price, regularPrice: base.regularPrice});
      }

      if (this.campaign.repair_free_glass_treatment) {
        base.services.filter(s => s.key === 'GLASS_TREATMENT').forEach(service => {
          service.price = 0;
          service.translations.price = this.translations.instant('SERVICES.GLASS_TREATMENT_CAMPAIGN_FREE');
        });
      }

      if (this.campaign.repair_free_washer_fluid) {
        base.services.filter(s => s.key === 'WASHER_FLUID').forEach(service => {
          service.price = 0;
          service.translations.price = this.translations.instant('SERVICES.WASHER_FLUID_CAMPAIGN_FREE');
        });
      }

      if (this.campaign.repair_free_wipers) {
        base.services.filter(s => s.key === 'WIPERS').forEach(service => {
          service.price = 0;
          service.translations.price = this.translations.instant('SERVICES.WIPERS_CAMPAIGN_FREE');
        });
      }
    }

    return base;
  }
}
