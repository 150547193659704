import {Component} from '@angular/core';
import {ServicesService} from '../../../../domain/services/services.service';
import {ServiceConfiguration} from '../../../../domain/services/service-configuration';

@Component({
  selector: 'services-section',
  templateUrl: './services-section.component.html',
  providers: [ServicesService]
})
export class ServicesSectionComponent {
  constructor(private services: ServicesService) {
  }

  get show(): boolean {
    return this.serviceConfiguration.package != null || this.serviceConfiguration.services.length > 0;
  }

  get serviceConfiguration(): ServiceConfiguration {
    return this.services.serviceConfiguration;
  }
}
