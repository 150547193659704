<h1 class="mb-40">{{text | translate}}</h1>
<div class="row">
  <div class="col-12">
    <h3 class="accent-color"> {{subTitle | translate}}</h3>
    <div>{{description | translate}}</div>
  </div>
</div>
<div class="row" *ngIf="showNavToStartPageBtn">
  <div class="col-12">
    <button type="button" class="btn mt-20" (click)="navigateToMyBooking()">
      {{"HOME" | translate}}
    </button>
  </div>
</div>

