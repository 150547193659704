import {Component, HostBinding, Input, TrackByFunction} from '@angular/core';
import {Package} from '../../../../../domain/services/package';
import {BookingScopedService} from '../../../../../domain/booking/booking-scoped.service';
import {Service} from '../../../../../domain/services/service';

@Component({
  selector: 'package-card',
  templateUrl: './package-card.component.html',
})
export class PackageCardComponent {
  @Input()
  package: Package = Package.placeholder;

  serviceTracker: TrackByFunction<Service> = (index, service) => service.key;

  constructor(private booking: BookingScopedService) {
  }

  @HostBinding('class.selected')
  get isSelected(): boolean {
    return this.package.selected;
  }

  @HostBinding('class.editable')
  get isEditable(): boolean {
    return !this.booking.loading;
  }

  clicked(): void {
    if (this.booking.loading) {
      return;
    }

    this.booking.togglePackage(this.package).subscribe();
  }

}
