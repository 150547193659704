import {Injectable} from '@angular/core';
import {ConfigurationService} from '../../core/configuration/configuration.service';
import {ServiceConfiguration} from './service-configuration';
import {Package} from './package';
import {Service} from './service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class BaseServiceConfigurationService {

  constructor(private configuration: ConfigurationService, private translations: TranslateService) {
  }

  get cvInsuranceDeductible(): number {
    return this.configuration.cvInsuranceDeductible || 7500;
  }

  get serviceConfiguration(): ServiceConfiguration {
    const conf = this.configuration.serviceConfig;

    if (!conf) {
      console.warn('No service configuration. Returning empty', this.configuration);
      return {
        package: null,
        services: [],
        total: 0
      };
    }

    const allServices = this.services(conf.services);

    const pack = this.package(conf.packages, allServices);

    const services = pack === null ? allServices : allServices.filter(s => !pack.included(s));

    const total = (pack?.effectivePrice || 0) + services.reduce((sum, service) => sum + service.effectivePrice, 0);

    return {
      package: pack,
      services,
      total
    };
  }

  package(packages: any, allServices: Service[]): Package | null {
    if (!packages || !Array.isArray(packages)) {
      console.warn('Bad package configuration. Returning null ', packages);
      return null;
    }

    if (packages.length === 0) {
      console.log('No package defined');
      return null;
    }

    try {
      const pack = packages[0];

      const included = pack.services;

      if (!included || !Array.isArray(included)) {
        console.warn('Bad service configuration in package. Returning null', packages, allServices);
        return null;
      }

      if (included.length === 0) {
        console.log('No included services in package. Returning null.', packages, allServices);
        return null;
      }

      const price = pack.price;

      if (!Number.isSafeInteger(price)) {
        console.warn('Price is not a number. Returning null', packages, allServices);
        return null;
      }

      const services = allServices.filter(service => included.some(s => s === service.key));

      const regularPrice = services.reduce((sum, service) => sum += service.price, 0);

      return new Package(price, regularPrice, services, {
        yes: this.translations.instant('SERVICES.PACKAGE_PRICE_YES'),
        price: this.translations.instant('SERVICES.PACKAGE_PRICE_PRICE', {price, regularPrice}),
        description: this.translations.instant('SERVICES.PACKAGE_PRICE_DESCRIPTION')
      });

    } catch (e) {
      console.warn('Could not create package using config. Returning null', packages, allServices, e);
      return null;
    }
  }

  private services(conf: any): Service[] {
    if (!conf || !Array.isArray(conf)) {
      console.warn('Bad service conf. Returning [].', conf);
      return [];
    }

    return conf.map(c => this.service(c))
      .filter(s => s != null) as Service[];
  }

  private service(conf: any): Service | null {
    if (!conf || !conf.key || typeof conf.price !== 'number' || !Number.isSafeInteger(conf.price)) {
      console.log('Bad service configuration', conf);
      return null;
    }

    return new Service(conf.key, conf.price, {
      yes: this.translations.instant(`SERVICES.${conf.key}_YES`),
      price: this.translations.instant(`SERVICES.${conf.key}_PRICE`, {price: conf.price})
    });
  }
}
