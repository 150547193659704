import {Component, OnInit} from '@angular/core';
import {Booking} from '../../../../../domain/booking/booking';
import {ActivatedRoute} from '@angular/router';
import {RbRoutingService} from '../../../../../core/services/rb-routing.service';
import {BookingStoreService} from '../../../../../domain/booking/booking-store.service';
import {BookingService} from '../../../../../domain/booking/booking.service';
import {AgreementService} from '../../../../../domain/agreement/agreement-service';
import {MY_BOOKING_ROUTES} from '../../../my-booking-routes';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  templateUrl: './agreements-sign-processing-page.component.html'
})
export class AgreementsSignProcessingPageComponent implements OnInit {
  booking: Booking | null = null;

  constructor(
    private route: ActivatedRoute,
    private rbRoutingService: RbRoutingService,
    private bookingStoreService: BookingStoreService,
    private bookingService: BookingService,
    private agreementService: AgreementService) {
  }

  ngOnInit(): void {
    this.booking = this.bookingStoreService.get();
    if (!this.booking) {
      throw Error('Booking not found');
    }

    this.agreementService.signed(this.booking.uuid).pipe(catchError(() => {
      this.navigateToErrorPage();
      return of(null);
    })).subscribe(myBooking => {
      if (myBooking == null) {
        return;
      }
      this.bookingStoreService.set(myBooking);
      this.navigateToSuccessPage();
    });
  }

  navigateToSuccessPage(): void {
    this.rbRoutingService.navToBookingsPath(this.booking, MY_BOOKING_ROUTES.AGREEMENTS_SIGN_SUCCESS, true);
  }

  navigateToErrorPage(): void {
    this.rbRoutingService.navToBookingsPath(this.booking, MY_BOOKING_ROUTES.AGREEMENTS_SIGN_ERROR, true);
  }
}
