import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import localeNo from '@angular/common/locales/nb';
import {sv as dateLocaleSv} from 'date-fns/locale/sv';
import {nb as dateLocaleNb} from 'date-fns/locale/nb';
import {SharedModule} from './shared/shared.module';
import {EnvironmentService} from './core/services/environment.service';
import {GlobalErrorHandler} from './core/error-handling/global-error-handler';
import {ErrorPageComponent} from './pages/error-page/error-page.component';
import {MyBookingModule} from './pages/my-booking/my-booking.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {StyleManagerService} from './core/services/style-manager.service';
import {NotFoundPageComponent} from './pages/my-booking/not-found-page/not-found-page.component';
import {MyBookingPageModule} from './pages/my-booking/my-booking-page/my-booking-page.module';
import {HeaderComponent} from './header/header.component';
import {NavigationService} from './core/services/navigation.service';
import {ConfigurationModule} from './core/configuration/configuration.module';
import {GlobalConfigService} from './configuration_old/global-config.service';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {InputMaskService, maskConfig} from './core/services/input-mask.service';
import {setDefaultOptions} from "date-fns/setDefaultOptions";

export function createTranslateLoader(http: HttpClient, config: GlobalConfigService): TranslateHttpLoader {
  const url = config.i18nBaseUrl || './assets/i18n/';
  return new TranslateHttpLoader(http, url, '.json?' + new Date().getTime()
  );
}

export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super('');
  }

  toString(): string {
    return this.service.currentLang;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    NotFoundPageComponent,
    HeaderComponent,
  ],
  imports: [
    ConfigurationModule,
    MyBookingPageModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MyBookingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, GlobalConfigService],
      },
    }),
    SharedModule,
  ],
  providers: [
    InputMaskService,
    NavigationService,
    provideEnvironmentNgxMask(maskConfig()),
    {provide: EnvironmentService, useFactory: environmentService},
    {provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService]},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translate: TranslateService, private env: EnvironmentService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(this.env.languageCode);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(this.env.languageCode);
    env.setHtmlLang(this.env.languageCode);

    // enables locales that will be used to set provider LOCALE_ID which is dynamically updated from DynamicLocaleId
    // later used in pipes
    registerLocaleData(localeSv, this.env.languageCodeSweden);
    registerLocaleData(localeNo, this.env.languageCodeNorway);

    const dateLocale = this.env.isNorway ? dateLocaleNb : dateLocaleSv;
    setDefaultOptions({locale: dateLocale});

    StyleManagerService.loadScript(this.env.themeCss);
  }
}

export function environmentService(): EnvironmentService {
  return new EnvironmentService(globalAppConfig);
}
