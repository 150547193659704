import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {slideInOutAnimationV2} from '../../../core/services/rb-animations';

@Component({
  templateUrl: './landing-page.component.html',
  animations: [slideInOutAnimationV2],
})
export class LandingPageComponent {

  prepareRoute(outlet: RouterOutlet): any {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animationDepth;
  }
}
