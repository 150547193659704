import {Injectable} from '@angular/core';
import {AgreementData} from './agreement-data';
import {Uuid} from '../uuid';

@Injectable({
  providedIn: 'root'
})
export class AgreementFormStoreService {
  agreementData: AgreementData | null = null;
  bookingUuid: Uuid | null = null;

  constructor() {
  }

  public set(bookingUuid: Uuid, data: AgreementData): void {
    this.bookingUuid = bookingUuid;
    this.agreementData = data;
  }

  public get(bookingUuid: Uuid): AgreementData | null {
    if (bookingUuid == null || this.bookingUuid == null || (bookingUuid !== this.bookingUuid)) {
      return null;
    }
    return this.agreementData;
  }
}
