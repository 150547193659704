import {Component, Input} from '@angular/core';
import {Booking} from '../../domain/booking/booking';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {EnvironmentService} from '../../core/services/environment.service';

@Component({
  selector: 'google-maps',
  templateUrl: './google-maps.component.html',
})
export class GoogleMapsComponent {
  query = '&q=place_id:';

  link: SafeResourceUrl | null = null;

  @Input() set booking(booking: Booking | null) {
    if (booking == null) {
      return;
    }
    this.setLink(booking);
  }

  constructor(private sanitizer: DomSanitizer,
              private environmentService: EnvironmentService) {
  }

  setLink(booking: Booking | null): void {
    if (booking == null) {
      return;
    }
    const placeId = booking.workshop.location.google_place_id;
    if (!placeId) {
      return;
    }
    this.link = this.transform(this.environmentService.mapsUrl + this.query + placeId);
  }

  transform(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
