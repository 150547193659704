<div class="container" data-cy="insurance_claim_success_page">
  <agreements-sign-verdict text="MY_BOOKING" subTitle="AGREEMENT_PROCESSING" [showNavToStartPageBtn]="false">
  </agreements-sign-verdict>
  <div class="row mt-10">
    <div class="col-12">
      {{'AGREEMENT_PROCESSING_DESCRIPTION' | translate}}
      <i class="fa fa-spinner fa-spin"></i>
    </div>
  </div>
</div>
