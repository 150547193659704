<div class="klarna-wrapper" *ngIf="showKlarna">
  <hr/>
  <div class="text" [innerHTML]="'KLARNA.1_INFO' | translate" *ngIf="stateRequestEmail">
  </div>
  <div class="text price"><strong>{{'KLARNA.2_PRICE' | translate:{amount: amount} }}</strong></div>
  <div *ngIf="stateError">
    <p class="text" style="color: #cc0000; font-weight: bold">{{'KLARNA.ERROR' | translate}}</p>
  </div>
  <div class="email" [formGroup]="form" *ngIf="stateRequestEmail || stateError">
    <p class="text" style="margin-bottom: 10px">{{'KLARNA.REQUEST_EMAIL' | translate}}</p>
    <input formControlName="email" required>
    <button type="button" data-cy="continue" (click)="selectEmail()" [disabled]="!form.valid">{{'KLARNA.CONTINUE' | translate}}</button>
  </div>
  <div *ngIf="stateChoosePaymentOption">
    <p class="text">{{'KLARNA.EMAIL' | translate}}:</p>
    <p class="text">{{email}} <a [routerLink]="[]" (click)="editEmail()">{{'KLARNA.CHANGE_EMAIL' | translate}}</a>
    </p>
    <p class="text" style="margin-top: 20px;" [innerHTML]="'KLARNA.3_PAYMENT_OPTION' | translate"></p>
    <div class="payment-options">
      <div class="payment-option" (click)="select(category)" *ngFor="let category of paymentCategories">
        <div class="input-container">
          <div class="radio-container">
            <div class="radio" [class.selected]="!category.hidden"></div>
          </div>
          <div class="description-container">
            <div>{{category.name}}</div>
          </div>
          <div class="logo-container"><img [src]="category.asset_urls.standard" alt="Klarna Logo"/></div>
        </div>
        <div class="klarna-container" *ngIf="!category.hidden">
          <klarna-payment-option [paymentCategory]="category"></klarna-payment-option>
          <button type="button"  data-cy="complete_purchase" (click)="authorize(category)">{{'KLARNA.COMPLETE_PURCHASE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <p *ngIf="stateCompleting" class="text" style="font-weight: bold">{{'KLARNA.COMPLETING' | translate}}
      <i class="fa fa-spinner fa-spin"></i>
    </p>
    <p *ngIf="stateApproved" class="text" style="font-weight: bold">{{'KLARNA.APPROVED' | translate}}</p>
  </div>
  <div class="text" style="margin-top: 15px;">
    <img [src]="logoUrl" width="100" alt="Klarna logotype"/>
  </div>
  <p style="text-align: center; margin-top: 30px;">
    <span [innerHTML]="'KLARNA.DO_YOU_NEED_TO_CONTACT_US_CALL_CUSTOMER_SERVICE' | translate"></span>
  </p>
</div>
