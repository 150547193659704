import { Component } from '@angular/core';
import { ConfigurationService } from '../configuration_old/configuration.service';

@Component({
  selector: 'mybooking-header',
  template: `
    <div class="brand-img"></div>
    <div
      class="contact"
      [innerHtml]="'HEADER.CONTACT' | translate: { phone: phone }"
    ></div>
    <div class="environment-label" [innerHtml]="label" *ngIf="label"></div>
  `,
})
export class HeaderComponent {
  constructor(private configuration: ConfigurationService) {}

  get phone(): string {
    return this.configuration.customerServicePhoneNumber;
  }

  get label(): string {
    return this.configuration.environmentLabel;
  }
}
