import {NgModule} from '@angular/core';
import {GLOBAL_APP_CONFIG} from './global-app-config.token';
import {ConfigurationService} from './configuration.service';

@NgModule({
  providers: [
    ConfigurationService,
    {provide: GLOBAL_APP_CONFIG, useValue: globalAppConfig}
  ],
  declarations: [],
  imports: []
})
export class ConfigurationModule {
}
