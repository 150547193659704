const State = {
  Reserved: 'BOOKING_STATE_RESERVED',
  Confirmed: 'BOOKING_STATE_CONFIRMED',
  Arrived: 'BOOKING_STATE_ARRIVED',
  Prechecked: 'BOOKING_STATE_PRECHECKED',
  ReadyForDeparture: 'BOOKING_STATE_READY_FOR_DEPARTURE',
  Departed: 'BOOKING_STATE_DEPARTED',
  Invoiced: 'BOOKING_STATE_INVOICED',
  Cancelled: 'BOOKING_STATE_CANCELLED',
};

export class BookingState {
  private readonly state!: string;

  constructor(stateVal: string | BookingState) {
    if (stateVal) {
      if (typeof stateVal === 'string') {
        this.state = stateVal;
      } else {
        this.state = stateVal.state;
      }
    }
  }

  public get isReserved(): boolean {
    return this.state === State.Reserved;
  }

  public get isConfirmed(): boolean {
    return this.state === State.Confirmed;
  }

  public get isArrived(): boolean {
    return this.state === State.Arrived;
  }

  public get isReadyForDeparture(): boolean {
    return this.state === State.ReadyForDeparture;
  }

  public get isDeparted(): boolean {
    return this.state === State.Departed;
  }

  public get isPrechecked(): boolean {
    return this.state === State.Prechecked;
  }

  public get isInvoiced(): boolean {
    return this.state === State.Invoiced;
  }

  public get isCancelled(): boolean {
    return this.state === State.Cancelled;
  }

  public get hasArrived(): boolean {
    return [
      State.Arrived,
      State.Prechecked,
      State.ReadyForDeparture,
      State.Departed,
      State.Invoiced,
    ].includes(this.state);
  }

  public static makeConfirmed(): BookingState {
    return BookingState.make(State.Confirmed);
  }

  public static makeArrived(): BookingState {
    return BookingState.make(State.Arrived);
  }

  public static makeReserved(): BookingState {
    return BookingState.make(State.Reserved);
  }

  public static makeReadyForDeparture(): BookingState {
    return BookingState.make(State.ReadyForDeparture);
  }

  public static makeDeparted(): BookingState {
    return BookingState.make(State.Departed);
  }

  public static makePrechecked(): BookingState {
    return BookingState.make(State.Prechecked);
  }

  public static makeInvoiced(): BookingState {
    return BookingState.make(State.Invoiced);
  }

  public static makeCancelled(): BookingState {
    return BookingState.make(State.Cancelled);
  }

  private static make(state: string): BookingState {
    return new BookingState(state);
  }

  public static isReservedOrConfirmed(state: BookingState): boolean {
    return (
      BookingState.makeReserved().equals(state) ||
      BookingState.makeConfirmed().equals(state)
    );
  }

  public static isArrivedOrPrev(state: BookingState): boolean {
    return (
      BookingState.isReservedOrConfirmed(state) ||
      BookingState.makeArrived().equals(state)
    );
  }

  public static isPrecheckOrPrev(state: BookingState): boolean {
    return (
      BookingState.isArrivedOrPrev(state) ||
      BookingState.makePrechecked().equals(state)
    );
  }

  public static isReadyForDepartureOrPrev(state: BookingState): boolean {
    return (
      BookingState.isPrecheckOrPrev(state) ||
      BookingState.makeReadyForDeparture().equals(state)
    );
  }

  public equals(stateVal: any): boolean {
    stateVal = new BookingState(stateVal);
    return stateVal.state === this.state;
  }

  public toCode(): string {
    return this.state;
  }

  public get code(): string {
    return this.state;
  }
}
