import {Injectable} from '@angular/core';
import {EnvironmentService} from './environment.service';

@Injectable({providedIn: 'root'})
export class LogService {
  constructor(private environmentService: EnvironmentService) {
  }

  error(...message: any[]): void {
    if (this.environmentService.isDebug) {
      // eslint-disable-next-line
      console.error('error', ...message);
    }
  }

  warn(...message: any[]): void {
    if (this.environmentService.isDebug) {
      // eslint-disable-next-line
      console.warn('warning', ...message);
    }
  }

  info(...message: any[]): void {
    if (this.environmentService.isDebug) {
      // eslint-disable-next-line
      console.info('info', ...message);
    }
  }
}
