import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {EMPTY, MonoTypeOperatorFunction, Observable, throwError} from 'rxjs';
import {catchError, first, tap} from 'rxjs/operators';
import {BookingService} from './booking.service';
import {BookingStoreService} from './booking-store.service';
import {MY_BOOKING_ROUTES} from '../../pages/my-booking/my-booking-routes';
import {HttpErrorResponse} from '@angular/common/http';
import {MyBooking} from "./my-booking";

@Injectable({
  providedIn: 'root'
})
export class BookingResolver {

  constructor(protected bookingService: BookingService, protected bookingStoreService: BookingStoreService, protected router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MyBooking> {
    return this.doGet(route, true);
  }

  doGet(route: ActivatedRouteSnapshot, addParam: boolean): Observable<MyBooking> {
    return this.bookingService.get(route.params.key, true, addParam)
      .pipe(first(), this.catchErrors(), tap(b => this.bookingStoreService.set(b)));
  }

  private catchErrors<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => {
      return source.pipe(catchError(error => {
        this.bookingStoreService.set(null);
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.router.navigate([MY_BOOKING_ROUTES.NOT_FOUND_PAGE]).then(/*DO NOTHING*/);
          return EMPTY;
        }

        return throwError(error);
      }));
    };
  }
}
