import {RikData} from '../rik-data';

export class Campaign {
  repair_free_wipers: boolean;
  repair_free_washer_fluid: boolean;
  repair_free_glass_treatment: boolean;
  repair_package_enabled: boolean;
  repair_package_price: number | null;
  replace_free_wipers: boolean;
  replace_free_washer_fluid: boolean;
  replace_free_glass_treatment: boolean;
  replace_package_enabled: boolean;
  replace_package_price: number | null;

  constructor(data: any) {
    const rikData = new RikData(data);
    this.repair_free_wipers = rikData.getBool('repair_free_wipers');
    this.repair_free_washer_fluid = rikData.getBool('repair_free_washer_fluid');
    this.repair_free_glass_treatment = rikData.getBool('repair_free_glass_treatment');
    this.repair_package_enabled = rikData.getBool('repair_package_enabled');
    this.repair_package_price = rikData.getNumber('repair_package_price');

    this.replace_free_wipers = rikData.getBool('replace_free_wipers');
    this.replace_free_washer_fluid = rikData.getBool('replace_free_washer_fluid');
    this.replace_free_glass_treatment = rikData.getBool('replace_free_glass_treatment');
    this.replace_package_enabled = rikData.getBool('replace_package_enabled');
    this.replace_package_price = rikData.getNumber('replace_package_price');
  }
}
