import {trigger, transition, group, query, style, animate, animateChild} from '@angular/animations';

export const slideInOutAnimation =
  trigger('routeAnimations', [
    transition('2 => 1, 3 => 2', [
      style({position: 'relative', height: '!'}),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        })
      ]),
      query(':enter', [
        style({left: '-100%'})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({left: '100%', opacity: 0}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({left: '0%'}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('1 => 2, 2 => 3', [
      style({position: 'relative', height: '!'}),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        })
      ]),
      query(':enter', [
        style({left: '100%'})
      ]),

      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({left: '-100%', opacity: 0}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({left: '0%'}))
        ])
      ]),
      query(':enter', animateChild()),
    ])]);

export const slideInOutAnimationV2 =
  trigger('routeAnimations', [
    transition('1 => 2, 2 => 3', [
      style({position: 'relative', height: '!'}),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        })
      ]),
      query(':enter', [
        style({left: '100%'})
      ]),

      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({'z-index': -1, opacity: 0}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({left: '0%'}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('2 => 1, 3 => 2', [
      style({position: 'relative', height: '!'}),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        })
      ]),
      query(':leave', [
        style({left: '0%', 'z-index': 1})
      ]),

      query(':enter', style({opacity: 0})),
      group([
        query(':enter', [
          animate('300ms ease-in', style({opacity: 1}))
        ]),
        query(':leave', [
          animate('300ms ease-out', style({left: '100%'}))
        ])
      ]),
      query(':leave', animateChild()),
    ])
  ]);
