import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ProgressService} from './core/services/progress.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
  title = 'ryds-my-booking';
  inProgress = true;

  constructor(private changeRef: ChangeDetectorRef,
              private progressService: ProgressService) {
  }

  ngOnInit(): void {
    this.progressService.active$.subscribe(inProgress => this.inProgress = inProgress);
  }

  ngAfterViewChecked(): void {
    this.changeRef.detectChanges();
  }
}
