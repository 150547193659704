import {Injectable} from '@angular/core';
import {IConfig} from 'ngx-mask';

export type InputMaskReturn = string;

@Injectable()
export class InputMaskService {

  get phoneMask() {
    return MaskSymbol.phone.concat(this.createDigit(14));
  }

  public orgNoMask(countryCode: string): string {
    if (countryCode.toUpperCase() === 'NO') {
      return this.createDigit(11);
    }
    if (countryCode.toUpperCase() === 'DK') {
      return this.createDigit(8);
    }
    return this.createDigit(10);
  }

  private createDigit(length: number): string {
    return MaskSymbol.digit.repeat(length);
  }
}

export enum MaskSymbol {
  digit = '1',
  phone = '2',
}

const MaskPatterns = {
  [MaskSymbol.digit]: {pattern: new RegExp(/\d/)},
  [MaskSymbol.phone]: {pattern: new RegExp(/[+\d]/)},
};

export function maskConfig(): Partial<IConfig> {
  return {
    validation: false,
    dropSpecialCharacters: false,
    patterns: MaskPatterns
  };
}
