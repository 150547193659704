import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MY_BOOKING_ROUTES } from 'src/app/pages/my-booking/my-booking-routes';
import { RbRoutingService } from './rb-routing.service';

@Injectable()
export class NavigationService {
  constructor(
    private rbRoutingService: RbRoutingService,
    private route: ActivatedRoute
  ) {}

  toRebooking() {
    this.rbRoutingService.navChild(this.route, MY_BOOKING_ROUTES.REBOOK);
  }
}
