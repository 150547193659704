import {Component, OnInit} from '@angular/core';
import {Booking} from '../../../domain/booking/booking';
import {BookingStoreService} from '../../../domain/booking/booking-store.service';
import {RbRoutingService} from '../../../core/services/rb-routing.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  templateUrl: './agreements-page.component.html'
})
export class AgreementsPageComponent implements OnInit {
  booking: Booking | null = null;

  constructor(private rbRoutingService: RbRoutingService,
              private route: ActivatedRoute,
              private bookingStoreService: BookingStoreService) {
  }

  ngOnInit(): void {
    this.booking = this.bookingStoreService.get();
    this.rbRoutingService.routeGuardConfirmed(this.booking);
  }

  navigateToMyBooking(): void {
    this.rbRoutingService.navParent(this.route);
  }
}
