import {Component, OnInit} from '@angular/core';
import {Booking} from '../../../domain/booking/booking';
import {ActivatedRoute} from '@angular/router';
import {BookingStoreService} from '../../../domain/booking/booking-store.service';
import {RbRoutingService} from '../../../core/services/rb-routing.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BookingService} from '../../../domain/booking/booking.service';
import {concatMap} from 'rxjs/operators';

@Component({
  templateUrl: './rebook-page.component.html',
})
export class RebookPageComponent implements OnInit {
  booking: Booking | null = null;
  sendInProgress = false;
  message: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
  email: UntypedFormControl = new UntypedFormControl('');

  constructor(private route: ActivatedRoute,
              private bookingStoreService: BookingStoreService,
              private bookingService: BookingService,
              private rbRoutingService: RbRoutingService) {
  }

  ngOnInit(): void {
    this.booking = this.bookingStoreService.get();
  }

  back(): void {
    this.rbRoutingService.navParent(this.route);
  }

  canSend(): boolean {
    return !this.sendInProgress && this.message.valid;
  }

  send(): void {
    if (this.booking == null || !this.canSend()) {
      this.message.markAsTouched({onlySelf: true});
      return;
    }

    this.sendInProgress = true;

    const message = this.message.value;
    const email = this.email.value;
    const $message = this.bookingService.sendMessage(this.booking.key, message, email);
    const $getBooking = this.bookingService.get(this.booking.key);

    $message.pipe(
      concatMap(() => $getBooking)
    ).subscribe((myBooking) => {
      this.bookingStoreService.set(myBooking);
      this.back();
    });
  }

  isFieldInvalid(): boolean {
    return this.message.invalid && this.message.touched;
  }
}
