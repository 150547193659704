import {parse} from "date-fns/parse";

export class RikData {
  private data: any;
  private validate = true;

  constructor(data: any, validate = true) {
    if (validate) {
      if (data === null) {
        throw new Error('RikData: data mustn\'t be null');
      }
      if (typeof data !== 'object') {
        throw new Error('RikData: data must be an object');
      }
      if (RikData.isArray(data)) {
        throw new Error('RikData: data mustn\'t be an array');
      }
    }
    this.data = data;
    this.validate = validate;
  }

  public static isArray(value: any): boolean {
    return value && typeof value === 'object' && value.constructor === Array;
  }

  public static isNumeric(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  public isEmpty(): boolean {
    return Object.keys(this.data).length === 0 && this.data.constructor === Object;
  }

  public getValue(propertyName: string): any {
    return this.data[propertyName];
  }

  public getString(propertyName: string): string | null {
    const value = this.data[propertyName];
    if (typeof value !== 'string') {
      return null;
    }
    return value.trim();
  }

  public getObject(propertyName: string, clazz: any): any {
    const value = this.getValue(propertyName);
    if (!value || typeof value !== 'object') {
      return null;
    }
    return new clazz(value);
  }

  public getRbType(propertyName: string, clazz: any): any {
    const value = this.getValue(propertyName);
    if (!value) {
      return null;
    }
    return new clazz(value);
  }

  public requireNumber(propertyName: string, config: { min: number | null } = {min: null}): number {
    const value = this.requireNotNull(propertyName);
    if (typeof value === 'string' && !value) {
      throw new Error('Property exists but it is an empty string: ' + propertyName);
    }
    if (!RikData.isNumeric(value)) {
      throw new Error('Property must have a numeric value: property = ' + propertyName + ', value = ' + value);
    }
    if (config.min && value < config.min) {
      throw new Error('Value less than allowed minimum: property = ' + propertyName + ', value = ' + value + ', min = ' + config.min);
    }
    return parseFloat(value);
  }

  public requireArray(propertyName: string): any[] {
    const value = this.requireNotNull(propertyName);
    if (!RikData.isArray(value)) {
      throw new Error('Property exists but it is not an array: ' + value);
    }
    return value;
  }

  public requireString(propertyName: string): string {
    const value = this.requireNotNull(propertyName);
    if (typeof value !== 'string') {
      throw new Error('Property value is not a string: property = ' + propertyName + ', value = ' + value);
    }
    return value;
  }

  public requireObject(propertyName: string, factory: any): any {
    const value = this.requireNotNull(propertyName);
    if (typeof value !== 'object') {
      throw new Error('Property value is not an object: property = ' + propertyName + ', value = ' + value);
    }
    return new factory(value);
  }

  public requireNotNull(propertyName: string): any {
    const propNames = propertyName.split('.', 2);
    let value = this.requireExists(propNames[0]);
    if (value == null) {
      throw new Error('Property exists but it is null: ' + propertyName);
    }

    if (propNames.length > 1) {
      const subLevel = new RikData(value);
      value = subLevel.requireExists(propNames[1]);
      if (value == null) {
        throw new Error('Property exists but it is null: ' + propertyName);
      }
    }

    return value;
  }

  public requireExists(propertyName: string): any {
    const value = this.data[propertyName];
    if (typeof value === 'undefined' && this.validate) {
      throw new Error('Required property missing: ' + propertyName);
    }
    return value;
  }

  public getBool(propertyName: string): boolean {
    const value = this.data[propertyName];
    if (!value) {
      return false;
    }
    if (RikData.isNumeric(value)) {
      return value !== 0 && value !== '0';
    }
    if (typeof value === 'boolean') {
      return value;
    }
    if (typeof value !== 'string') {
      return false;
    }
    return value !== 'false';
  }

  public getArrayObject(propertyName: string, clazz: any): any[] {
    const value = this.data[propertyName];
    if (!RikData.isArray(value)) {
      return [];
    }
    return value.map((val: any) => this.getObjectInArray(val, clazz));
  }

  private getObjectInArray(value: any, clazz: any): any {
    if (!value || typeof value !== 'object') {
      return null;
    }
    return new clazz(value);
  }

  getEnumString(propertyName: string, clazz: any, def: any = null): any {
    const keys = Object.keys(clazz).filter(key => clazz[key] === this.data[propertyName]);
    return keys.length > 0 ? clazz[keys[0]] : def;
  }

  getNumber(propertyName: string, fallback: number | null = null): number | null {
    const value = this.data[propertyName];
    if (RikData.isNumeric(value)) {
      return value;
    }
    return fallback;
  }

  getDate(propertyName: string) {
    const value = this.data[propertyName];

    if (value === null) {
      return null;
    }

    if (value instanceof Date) {
      return new Date(value.valueOf());
    }
    return parse(value, 'yyyyMMdd', new Date());
  }
}
