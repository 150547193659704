import {HttpErrorResponse} from '@angular/common/http';

export class ErrorHelper {
  static getServerMessage(error: HttpErrorResponse): string {
    if (error.status === 0 && !navigator.onLine) {
      return 'ERROR_API_OFFLINE';
    }
    if (error.error && error.error.errorKey) {
      return `ERROR_API_${error.error.errorKey}`;
    }
    return '';
  }

  private static getSeverErrorCodeIfAny(e: any, defaultError: string): string {
    return e.errorcode ? e.errorcode : defaultError;
  }

  static getServerStack(e: HttpErrorResponse): string {
    return e.message ? e.message : JSON.stringify(e);
  }

  static getClientMessage(error: Error): string {
    return error.message;
  }

  static getClientStack(error: Error): any {
    return error.stack;
  }
}
