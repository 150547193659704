<div class="container center-text" data-cy="error_page">
  <div class="mb-40">
    <h2>{{title | translate}}</h2>
    <p>{{message | translate}}</p>
    <p>{{'ERROR_DESCRIPTION' | translate}}</p>
  </div>
  <section class="layout-content">
      <button type="button" class="btn btn-secondary m-10" data-cy="navigate" *ngIf="canRetry()"
              (click)="onNavigate()">{{'RETRY' | translate}}
      </button>
      <button type="button" class="btn m-10" data-cy="navigate" *ngIf="booking"
              (click)="navigateToStart()">{{'HOME' | translate}}
      </button>
  </section>
</div>
