import {Component, OnInit} from '@angular/core';

@Component({
  templateUrl: './agreements-sign-error-page.component.html',
})
export class AgreementsSignErrorPageComponent implements OnInit {

  constructor() {
 }

  ngOnInit(): void {
  }
}
