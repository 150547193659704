import {Injectable} from '@angular/core';
import {CampaignService} from '../campaign/campaign.service';
import {BaseServiceConfigurationService} from './base-service-configuration.service';
import {ServiceConfiguration} from './service-configuration';
import {BookingScopedService} from '../booking/booking-scoped.service';

@Injectable()
export class ServicesService {

  constructor(private booking: BookingScopedService, private campaigns: CampaignService, private base: BaseServiceConfigurationService) {
  }

  get serviceConfiguration(): ServiceConfiguration {
    const campaign = this.campaigns.applicableCampaign;

    const {package: pkg, services} = campaign ? campaign.serviceConfiguration : this.base.serviceConfiguration;

    const booking = this.booking.booking;

    pkg?.init(booking);
    services.forEach(service => service.init(booking));

    const total = (pkg?.effectivePrice || 0) + services.reduce((sum, service) => sum + service.effectivePrice, 0);

    return {
      package: pkg,
      services: services.filter(s => booking && s.offeredForBooking(booking)),
      total
    };
  }
}
