import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {APP_ROUTES} from './app-routes';
import {ErrorPageComponent} from './pages/error-page/error-page.component';
import {MY_BOOKING_ROUTES} from './pages/my-booking/my-booking-routes';

const routes: Routes = [
  {path: '', redirectTo: MY_BOOKING_ROUTES.NOT_FOUND_PAGE, pathMatch: 'full'},
  {path: 'b/:key', redirectTo: APP_ROUTES.MY_BOOKING_PAGE},
  {path: 'b/:key' + MY_BOOKING_ROUTES.AGREEMENTS_SIGN_SUCCESS, redirectTo: MY_BOOKING_ROUTES.AGREEMENTS_SIGN_SUCCESS},
  {path: 'b/:key' + MY_BOOKING_ROUTES.AGREEMENTS_SIGN_PROCESSING, redirectTo: MY_BOOKING_ROUTES.AGREEMENTS_SIGN_PROCESSING},
  {path: 'b/:key' + MY_BOOKING_ROUTES.AGREEMENTS_SIGN_REJECT, redirectTo: MY_BOOKING_ROUTES.AGREEMENTS_SIGN_REJECT},
  {path: 'b/:key' + MY_BOOKING_ROUTES.AGREEMENTS_SIGN_ERROR, redirectTo: MY_BOOKING_ROUTES.AGREEMENTS_SIGN_ERROR},
  {
    path: APP_ROUTES.ERROR_PAGE,
    component: ErrorPageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
