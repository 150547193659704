import {Service} from './service';
import {Booking} from '../booking/booking';

export class Package {

  constructor(public price: number,
              readonly regularPrice: number,
              readonly services: Service[],
              readonly translations: PackageTranslations) {
  }

  included(service: Service): boolean {
    return this.services.some(s => s.key === service.key);
  }

  get selected(): boolean {
    return !this.services.some(s => !s.selected);
  }

  get effectivePrice(): number {
    if (this.selected) {
      return this.price;
    }

    return this.services.reduce((sum, service) => sum + service.effectivePrice, 0);
  }

  init(booking: Booking): void {
    this.services.forEach(s => s.init(booking));
  }

  static get placeholder(): Package {
    return new Package(0, 0, [], {description: '', price: '', yes: ''});
  }
}

interface PackageTranslations {
  yes: string;
  price: string;
  description: string;
}
