import {Component, OnInit} from '@angular/core';
import {MY_BOOKING_ROUTES} from '../../../my-booking-routes';
import {Booking} from '../../../../../domain/booking/booking';
import {AgreementData} from '../../../../../domain/agreement/agreement-data';
import {BookingStoreService} from '../../../../../domain/booking/booking-store.service';
import {AgreementService} from '../../../../../domain/agreement/agreement-service';
import {AgreementFormStoreService} from '../../../../../domain/agreement/agreement-form-store.service';
import {finalize} from 'rxjs/operators';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BookingService} from '../../../../../domain/booking/booking.service';
import {concat} from 'rxjs';
import {RbRoutingService} from '../../../../../core/services/rb-routing.service';

@Component({
  templateUrl: './agreements-courtesy-vehicle-extra-page.component.html',
})
export class AgreementsCourtesyVehicleExtraPageComponent implements OnInit {
  booking: Booking | null = null;
  agrementData: AgreementData | null = null;
  sendInProgress = false;
  isNavigating = false;

  chooseExtra: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);

  constructor(private bookingStoreService: BookingStoreService,
              private bookingService: BookingService,
              private agreementService: AgreementService,
              private rbRoutingService: RbRoutingService,
              private agreementFormStoreService: AgreementFormStoreService) {
  }

  ngOnInit(): void {
    this.booking = this.bookingStoreService.get();
    this.rbRoutingService.routeGuardConfirmed(this.booking);
    if (!this.booking) {
      throw Error('Booking not found');
    }
    this.agrementData = this.agreementFormStoreService.get(this.booking.uuid);
    // this.chooseExtra.setValue(this.booking.extra_courtesy_vehicle_insurance);
  }

  send(): void {
    if (!this.booking) {
      return;
    }
    const data = this.agreementFormStoreService.get(this.booking.uuid);
    if (!data) {
      return;
    }

    this.sendInProgress = true;
    const $chooseExtra = this.bookingService.changes(this.booking.uuid, {'job.extra_courtesy_vehicle_insurance': this.chooseExtra.value});
    const $agreements = this.agreementService.create(this.booking.uuid, data);

    concat($chooseExtra, $agreements).pipe(
      finalize(() => {
        this.sendInProgress = false;
      })
    ).subscribe((res) => {
      if (res && 'url' in res) {
        this.isNavigating = true;
        window.location.href = res.url;
      }
    });
  }

  navigateBack(): void {
    this.rbRoutingService.navToBookingsPath(this.booking, MY_BOOKING_ROUTES.AGREEMENTS_PAGE);
  }

  canSave(): boolean {
    return this.chooseExtra.valid && !this.sendInProgress && !this.isNavigating;
  }
}
