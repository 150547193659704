import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html'
})
export class PageTitleComponent {

  @Input()
  text = '';

  @Input()
  showArrow = false;

  @Input()
  disabled = false;

  @Output()
  backChange: EventEmitter<any> = new EventEmitter<any>();

  back(): void {
    if (this.disabled) {
      return;
    }
    this.backChange.emit();
  }
}
