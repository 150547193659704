<div class="container" data-cy="not_found_page">
  <div class="mb-40">
    <h1>{{'BOOKING_NOT_FOUND_TITLE' | translate}}</h1>
    <p>{{'BOOKING_NOT_FOUND_DESC_1' | translate}}</p>
    <p>{{'BOOKING_NOT_FOUND_DESC_2' | translate}}</p>
    <p>{{'BOOKING_NOT_FOUND_DESC_3' | translate}}</p>
    <button type="button" class="btn m-10" (click)="navToHomepage()"
            data-cy="navigate">{{'BOOKING_NOT_FOUND_NAV_TO_HOMEPAGE' | translate}}
    </button>
  </div>
</div>
