import {Injectable} from '@angular/core';
import {BaseServiceConfigurationService} from '../services/base-service-configuration.service';
import {TranslateService} from '@ngx-translate/core';
import {CampaignWrapper} from "./campaign-wrapper";
import {BookingStoreService} from "../booking/booking-store.service";

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private bookingStoreService: BookingStoreService, private base: BaseServiceConfigurationService, private translate: TranslateService) {
  }

  get applicableCampaign(): CampaignWrapper | null {
    const booking = this.bookingStoreService.get();
    const campaign = this.bookingStoreService.getCampaign();
    if (booking == null || campaign == null) {
      return null;
    }
    return new CampaignWrapper(campaign, this.base.serviceConfiguration, booking, this.translate);
  }
}
