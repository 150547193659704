import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Booking} from '../../../domain/booking/booking';
import {MY_BOOKING_ROUTES} from '../my-booking-routes';
import {BookingStoreService} from '../../../domain/booking/booking-store.service';
import {RbRoutingService} from '../../../core/services/rb-routing.service';
import {KlarnaStatus} from '../../../shared/klarna/klarna-status';
import {ConfigurationService} from 'src/app/configuration_old/configuration.service';

@Component({
  templateUrl: './my-booking-page.component.html',
})
export class MyBookingPageComponent implements OnInit {
  booking: Booking | null = null;
  extrasText = '';
  near: Booking[] | null = null;
  ical: string | null = null;

  constructor(
    private rbRoutingService: RbRoutingService,
    private route: ActivatedRoute,
    private configurationService: ConfigurationService,
    private bookingStoreService: BookingStoreService
  ) {}

  ngOnInit(): void {
    this.getBookingFromStore();
  }

  private getBookingFromStore(): void {
    this.booking = this.bookingStoreService.get();
    this.near = this.bookingStoreService.getNear();
    this.ical = this.bookingStoreService.getIcal();
  }

  navigateToInsuranceClaim(): void {
    this.navigate(MY_BOOKING_ROUTES.AGREEMENTS_PAGE);
  }

  navigateToPayment(): void {
    this.navigate(MY_BOOKING_ROUTES.PAYMENT);
  }

  get showPayment(): boolean {
    return (
      this.booking != null && this.booking.klarna_status === KlarnaStatus.OK
    );
  }

  get isPaymentCompleted(): boolean {
    return (
      this.booking != null &&
      this.booking.klarna_status === KlarnaStatus.COMPLETED
    );
  }

  get phone(): string {
    return this.configurationService.customerServicePhoneNumber;
  }

  private navigate(childPath: string): void {
    this.rbRoutingService.navChild(this.route, childPath);
  }
}
