import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Booking} from '../../domain/booking/booking';

@Injectable({
  providedIn: 'root'
})
export class RbRoutingService {

  constructor(private router: Router) {
  }

  navToBooking(booking: Booking | null): void {
    if (!booking) {
      throw new Error('Routing provided with empty booking.');
    }
    this.router.navigate([this.getCurrentBookingUrl(booking)]).then(/* DO NOTHING */);
  }

  getCurrentBookingUrl(booking: Booking): string {
    return 'bookings/' + booking.key;
  }

  navParent(route: ActivatedRoute): void {
    this.router.navigate(['../'], {relativeTo: route}).then(/* DO NOTHING */);
  }

  navGrandParent(route: ActivatedRoute): void {
    this.router.navigate(['../../'], {relativeTo: route}).then(/* DO NOTHING */);
  }

  navChild(route: ActivatedRoute, path: string): void {
    this.router.navigate([path], {relativeTo: route}).then(/* DO NOTHING */);
  }

  navToBookingsPath(booking: Booking | null, path: string, replaceUrl = false): void {
    if (!booking || !path) {
      throw new Error('Routing provided with empty booking:' + booking + ' or path: ' + path);
    }
    this.router.navigate([this.getCurrentBookingUrl(booking) + '/' + path], {replaceUrl}).then(/* DO NOTHING */);
  }

  routeGuardConfirmed(booking: Booking | null): void {
    if (booking == null) {
      throw new Error('Booking should never be null');
    }
    if (!booking.isConfirmedOrPrev()) {
      this.navToBooking(booking);
    }
  }
}
