import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rb-grid-row',
  template: ` <div class="key" [innerHtml]="key | translate"></div>
    <div class="value" *ngIf="!renderContent" [innerHtml]="value | translateOrUnknown"></div>
    <div class="value" *ngIf="renderContent">
      <ng-content></ng-content>
    </div>`,
})
export class RBGridRowComponent {
    @Input()
    key = '';
    @Input()
    value: any = 'NOTSET';

    get renderContent(): boolean {
        return this.value == 'NOTSET';
    }
}
