import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {RouterExtractorService} from '../../core/error-handling/router-extractor-service';
import {APP_ROUTES} from '../../app-routes';
import {Booking} from '../../domain/booking/booking';
import {BookingStoreService} from '../../domain/booking/booking-store.service';
import {RbRoutingService} from '../../core/services/rb-routing.service';

@Component({
  templateUrl: './error-page.component.html'
})
export class ErrorPageComponent implements OnInit {
  message!: string;
  title = 'ERROR_TITLE';
  previous: string | undefined;
  booking: Booking | null = null;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private rbRoutingService: RbRoutingService,
              private location: Location,
              private bookingStoreService: BookingStoreService,
              private routerExtractorService: RouterExtractorService) {
  }

  ngOnInit(): void {
    this.previous = this.routerExtractorService.getPreviousUrl();
    this.booking = this.bookingStoreService.get();
    this.activatedRoute.paramMap.pipe(
      map(() => window.history)
    ).subscribe((state: any) => {
      this.message = state.message;
      if (state.title) {
        this.title = state.title;
      }
    });
  }

  canRetry(): boolean {
    return this.booking != null
      && this.previous != null
      && this.previous !== '/' + APP_ROUTES.ERROR_PAGE
      && this.previous !== '/' + APP_ROUTES.MY_BOOKING_PAGE;
  }

  navigateToStart(): void {
    this.rbRoutingService.navToBooking(this.booking);
  }

  onNavigate(): void {
    const previous = this.routerExtractorService.getPreviousUrl();
    if (previous) {
      this.router.navigateByUrl(previous).then();
    }
  }
}
