import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'translateOrUnknown'
})
export class TranslateOrUnknownPipe implements PipeTransform {

  public constructor(private translateService: TranslateService) {
  }

  public transform(value: any): any {
    return value ? this.translateService.instant(value) : this.translateService.instant('UNKNOWN');
  }
}
