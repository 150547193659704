import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {EnvironmentService} from '../../core/services/environment.service';

@Directive({
  selector: '[featureRebooking]',
})
export class RebookingFeatureDirective implements OnInit {

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private environment: EnvironmentService) {
  }

  ngOnInit(): void {
    if (this.environment.hasFeature('Rebook')) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
